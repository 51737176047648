import React from 'react'

const ProcessInfo = ({ image, title, desc }) => {
  return (
    <>
        <div className="grid grid-cols-1 md:grid-cols-2 flex-col md:flex-row items-center md:w-4/5">
            <div className="flex gap-6 md:flex-row flex-col items-center">
                <img src={image} alt="" />
                <h5 className="heading-five text-gray500" >{title}</h5>
            </div>
            <div className='flex'>
                <p className='text-gray text-center md:text-left'>
                    {desc}
                </p>
            </div>
        </div>
    </>
  )
}

export default ProcessInfo