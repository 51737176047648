import React from 'react'
import { Link } from 'react-router-dom'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'
// Import Swiper styles
import 'swiper/css';

import GroupPic from '../assets/images/team/group+picture.webp'
import MissionVideo from '../assets/videos/mission+vid.mp4'
import VisionVideo from '../assets/videos/vision+vid.mp4'
import CircleIcon from '../assets/svgs/icons/circle+icon.svg'
import TrophyIcon from '../assets/svgs/icons/values/trophy+icon.svg'
import PartnerIcon from '../assets/svgs/icons/values/partner+icon.svg'
import LightbulbIcon from '../assets/svgs/icons/values/light+bulb+icon.svg'
import CollaboIcon from '../assets/svgs/icons/values/collabo+icon.svg'
import FocusIcon from '../assets/svgs/icons/values/focus+icon.svg'
import SocialIcon from '../assets/svgs/icons/values/social+icon.svg'
import Adetola from '../assets/images/team/adetola.png'
import Tosin from '../assets/images/team/tosin.png'
import Lilian from '../assets/images/team/lilian.png'
import Tag from '../components/Tag'
import ValuesCard from '../components/ValuesCard'
import LeftArrow from '../assets/svgs/icons/left-arrow.svg'
import RightArrow from '../assets/svgs/icons/right-arrow.svg'
import DownArrow from '../assets/svgs/icons/arrow-down.svg'
import PageTitle from '../components/PageTitle'
import TeammateCard from '../components/TeammateCard';
import Ayoola from '../assets/images/team/ayoola.png'
import Ayomide from '../assets/images/team/ayomide.png'
import Triumph from '../assets/images/team/triumph.png'
import Modupe from '../assets/images/team/modupe.png'
import Doose from '../assets/images/team/doose.png'
import Cornelius from '../assets/images/team/cornelius.png'
import Emmanuel from '../assets/images/team/Emmanuel.png'
import Lekan from '../assets/images/team/lekan.png'
import Efe from '../assets/images/team/efe.png'
import Sammy from '../assets/images/team/sammy.jpg'
import Attah from '../assets/images/team/attah.png'
import Martha from '../assets/images/team/martha.png'
import Muze from '../assets/images/team/muze.png'
import Amani from '../assets/images/team/amani.png'
import GlassEffect from '../assets/svgs/bg/glass-effect2.svg'

const About = () => {
    React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page
    // const swiper = new Swiper();

  return (
    <>
    {/* Set Page title */}
    <PageTitle title="About | SeamHealth Website " /> 
        <section className='pt-24 md:pt-52 about-us-section'>
            <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-20 gap-4 p-4 md:p-8">
                <div data-aos="fade-up" className="flex flex-col gap-4">
                    <Tag title={"About us"} />
                    <h4 className="heading-three">Get to know who we are</h4>
                    <a href="#our-story" className='btn btn-primary w-max'>Learn more about our company <img src={DownArrow} alt="" /> </a>
                </div>

                <div data-aos="fade-up">
                    <p className='text-gray'>
                    SeamHealth is a leader in healthcare innovation, dedicated to providing comprehensive consulting and software development services. 
                    </p>
                </div>
            </div>

            <div>
                <img src={GroupPic} alt="" draggable={false} />
            </div>
        </section>

        {/* our story */}
        <section id="our-story" className='story-section p-4 md:p-8 pt-16 md:pt-36 pb-24'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 h-full">
                <div className='text-white flex flex-col justify-between items-start'>
                    <h4 data-aos="fade-up" className="heading-three">Our Story</h4>
                    <img src={GlassEffect} className='glass-effect' alt="" />

                    <a href="#mission-section">
                        <div className='message-cta flex gap-3 items-center justify-end'>
                            <img src={CircleIcon} alt="" />
                            <p className='text'>Mission</p>
                        </div>
                    </a>
                </div>

                <div className='text-white flex flex-col gap-y-12'>
                    <p data-aos="fade-up">SeamHealth Innovations has been operating since 2016 and has grown to become a trusted partner for government, non-governmental, corporate, and private institutions in the healthcare sector. We take pride in our extensive industry knowledge and deep understanding of the unique challenges faced by healthcare providers.</p>
                    <p data-aos="fade-up">SeamHealth Innovations has been operating since 2016 and has grown to become a trusted partner for government, non-governmental, corporate, and private institutions in the healthcare sector. We take pride in our extensive industry knowledge and deep understanding of the unique challenges faced by healthcare providers.</p>
                </div>
            </div>
        </section>

        {/* mission section */}
        <section id='mission-section' className="mission-section pt-8 md:pt-36">
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-8">
                    <h4 data-aos="fade-up" className="heading-three">Mission</h4>
                    <p data-aos="fade-up" className='text-gray'>Our mission is to empower healthcare organizations with innovative technology solutions and strategic consulting services to improve patient outcomes and operational efficiency.</p>
                </div>

                <div data-aos="fade-up" className='mission-vid mt-8 md:mt-0'>
                    <video autoPlay loop muted style={{height : 607}}>
                        <source src={MissionVideo} type="video/mp4" />
                    </video>
                </div>
            </div>
        </section>

        {/* Vision Section */}
        <section className="vision-section pt-16 md:pt-36 flex flex-col items-center justify-center" id="vision-section">
            <div className='grid grid-cols-1 md:grid-cols-2 p-4 md:p-8'>
                <div>
                    <h4 data-aos="fade-up" className="heading-three">Vision</h4>
                </div>

                <div>
                    <p data-aos="fade-up" className='text-gray'>
                        We envision a healthcare landscape where every organization can harness the full potential of technology to deliver exceptional care and enhance overall wellness. 
                    </p>
                </div>
            </div>
            <div data-aos="fade-up" className='vision-vid'>
                    <video autoPlay loop muted style={{height : "95vh", backgroundAttachment : 'fixed'}} draggable="false">
                        <source src={VisionVideo} type="video/mp4" />
                    </video>
                </div>
        </section>


        {/* Values Section */}
        <section className="values-section pt-16 md:pt-36" id="values-section">
            <div className='grid grid-cols-1 md:grid-cols-2 p-4 md:p-8 gap-4'>
                <div>
                    <h4 data-aos="fade-up" className="heading-three">Our core values</h4>
                </div>

                <div>
                    <p data-aos="fade-up" className=''>
                        Our core values guide our decision-making, shape our culture, and ensure that we consistently deliver exceptional services that make a meaningful impact on our clients and the communities they serve.
                    </p>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-4 md:p-8">
                <ValuesCard 
                icon={TrophyIcon}
                title={"Excellence"}
                desc={"We are dedicated to excellence, continuously improving by setting high standards to surpass expectations in all our services and solutions."}
                />

<ValuesCard 
                icon={PartnerIcon}
                title={"Integrity"}
                desc={"We uphold the highest ethical standards in our interactions, fostering trust and transparency with clients, partners, and stakeholders."}
                />

<ValuesCard 
                icon={CollaboIcon}
                title={"Collaboration"}
                desc={"We believe in the power of collaboration and teamwork. We work closely with our clients, listening to their needs and goals and leveraging our collective expertise to develop tailored solutions that drive sustainable success."}
                />

<ValuesCard 
                icon={LightbulbIcon}
                title={"Innovation"}
                desc={"Innovation is central to our approach. We embrace emerging technologies, challenge conventional thinking, and find creative solutions to address evolving healthcare needs."}
                />

<ValuesCard 
                icon={TrophyIcon}
                title={"Client Focus"}
                desc={"Innovation is central to our approach. We embrace emerging technologies, challenge conventional thinking, and find creative solutions to address evolving healthcare needs."}
                />

<ValuesCard 
                icon={SocialIcon}
                title={"Social Responsibility"}
                desc={"Contributing to better healthcare, we advocate for solutions that improve outcomes, access, and health equity."}
                />
            </div>

            <a href="#team-section" className='w-max'>
                <div className='message-cta flex gap-3 items-center justify-start p-8'>
                    <img src={CircleIcon} alt="" />
                    <p className='text'>Team members</p>
                </div>
            </a>
        </section>

        <section className="py-16">
            <div className="flex flex-col md:flex-row justify-between items-center p-8">
                <div>
                    <h4 data-aos="fade-up" className='heading-three'>The faces behind our success</h4>
                    <p data-aos="fade-up" className='text-gray'>Meet the talents that drives our innovation</p>
                </div>

            </div>

            <div className="hidden md:block p-8">
                <Swiper
                modules={[Navigation, Pagination, A11y, Autoplay]}
                spaceBetween={50}
                slidesPerView={3}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                navigation
                // pagination={{clickable: true}}
                loop={true}
                
                >
                <SwiperSlide>
                    <TeammateCard
                        image={Adetola}
                        name={"Adetola Olateju"}
                        role={"CEO & Managing Partner"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Tosin}
                        name={"Tosin Ajayi"}
                        role={"Project Manager"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Lilian}
                        name={"Lilian Ekpere"}
                        role={"Senior Product Manager"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Amani}
                        name={"Victor Nnamani"}
                        role={"Product Analyst"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Doose}
                        name={"Ayodeji Doose"}
                        role={"Admin & Finance"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Martha}
                        name={"Martha Aondoakaa"}
                        role={"Assist. Admin"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Cornelius}
                        name={"Cornelius Ifoghale"}
                        role={"Senior Frontend Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Ayomide}
                        name={"Ayomide Adigun"}
                        role={"Frontend Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Efe}
                        name={"Efe Osahon"}
                        role={"Frontend Software Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Ayoola}
                        name={"Tosin Ayoola"}
                        role={"Frontend Software Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Muze}
                        name={"Muze Etoma"}
                        role={"Senior Backend Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Modupe}
                        name={"Modupe Ade-Onojobi"}
                        role={"Backend Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Lekan}
                        name={"Lekan Adetunmbi"}
                        role={"Backend Software Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Attah}
                        name={"Emmanuel Attah"}
                        role={"Backend Software Engineer"}
                    />
                </SwiperSlide>
                
                <SwiperSlide>
                    <TeammateCard
                        image={Triumph}
                        name={"Triumph Anazia"}
                        role={"Senior UI/UX Designer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Emmanuel}
                        name={"Olaosebikan Emmanuel"}
                        role={"UI/UX Designer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Sammy}
                        name={"Samuel Ezekiel"}
                        role={"Quality Assurance Engineer"}
                    />
                </SwiperSlide>
                
                
                </Swiper>
            </div>

            <div className="for-mobile md:hidden p-4 md:p-8">
                <Swiper
                modules={[Navigation, Pagination, A11y, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                centeredSlides
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                navigation
                // pagination={{clickable: true}}
                loop={true}
                
                >
                <SwiperSlide>
                    <TeammateCard
                        image={Adetola}
                        name={"Adetola Olateju"}
                        role={"CEO & Managing Partner"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Tosin}
                        name={"Tosin Ajayi"}
                        role={"Project Manager"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Lilian}
                        name={"Lilian Ekpere"}
                        role={"Senior Product Manager"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Doose}
                        name={"Ayodeji Doose"}
                        role={"Admin & Finance"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Martha}
                        name={"Martha Aondoakaa"}
                        role={"Assist. Admin"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Cornelius}
                        name={"Cornelius Ifoghale"}
                        role={"Senior Frontend Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Ayomide}
                        name={"Ayomide Adigun"}
                        role={"Frontend Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Efe}
                        name={"Efe Osahon"}
                        role={"Frontend Software Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Ayoola}
                        name={"Tosin Ayoola"}
                        role={"Frontend Software Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Modupe}
                        name={"Modupe Ade-Onojobi"}
                        role={"Backend Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Lekan}
                        name={"Lekan Adetunmbi"}
                        role={"Backend Software Engineer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Attah}
                        name={"Emmanuel Attah"}
                        role={"Backend Software Engineer"}
                    />
                </SwiperSlide>
                
                <SwiperSlide>
                    <TeammateCard
                        image={Triumph}
                        name={"Triumph Anazia"}
                        role={"Senior UI/UX Designer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Emmanuel}
                        name={"Olaosebikan Emmanuel"}
                        role={"UI/UX Designer"}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TeammateCard
                        image={Sammy}
                        name={"Samuel Ezekiel"}
                        role={"Quality Assurance Engineer"}
                    />
                </SwiperSlide>
                
                
                </Swiper>
            </div>
        </section>
    </>
  )
}

export default About