import React from 'react'
import { Link } from 'react-router-dom'


const Thumbnail = ({ image, title, desc, link }) => {
  return (
    <>
        <Link to={link}>
          <div className='thumbnail flex-col flex-end'
          style={{
              backgroundRepeat : 'no-repeat',
              backgroundImage : 'url('+image+')'
              }}>
              <div className="title p-8 py-12">
                  <h4 className="heading-four text-white mb-4">{title}</h4>
                  <p className="text-white desc">{desc}</p>
              </div>
          
          </div>
        </Link>
    </>
  )
}

export default Thumbnail