import {React, useState, useLayoutEffect, useRef, useEffect} from 'react'
import emailjs from '@emailjs/browser';
import UserIcon from '../assets/svgs/icons/user+icon.svg'
import EmailIcon from '../assets/svgs/icons/mail+icon.svg'
import PhoneIcon from '../assets/svgs/icons/phone+icon.svg'
import Tag from '../components/Tag'
import CircleIcon from '../assets/svgs/icons/circle+icon.svg'
import CircleIconAlt from '../assets/svgs/icons/circle+icon+alt.svg'
import PageTitle from '../components/PageTitle'
import MapBG from '../assets/svgs/bg/map-bg2.svg'
import ReCAPTCHA from "react-google-recaptcha"

const Getintouch = () => {
    // useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState(false)
    const [submitBtn, setsubmitBtn] = useState(true)

     // error states
     const [nameError, setnameError] = useState()
     const [emailError, setemailError] = useState()
     const [phoneError, setphoneError] = useState()
     const [messageError, setmessageError] = useState()

     const recaptcha = useRef()


    useEffect(() => {
      let i = true
      if(name !== "" && email !== "" && message !== ""){
        setsubmitBtn(true)
      }else{
        setsubmitBtn(false)
      }
    
      return () => {
        i = false
      }
    },[name, email, message])
    

     emailjs.init({
        publicKey: process.env.REACT_APP_EMAILJS_KEY,
        // Do not allow headless browsers
        blockHeadless: true,
        blockList: {
          // Block the suspended emails
          // The variable contains the email address
          watchVariable: 'userEmail',
        },
        limitRate: {
          // Set the limit rate for the application
          id: 'app',
          // Allow 1 request per 10s
          throttle: 10000,
        },
      });

     const clearFields = () =>{
        setName("")
        setPhone("")
        setMessage("")
        setEmail("")
        
    }

    const submitForm = async (e) => {
        e.preventDefault()
        const captchaValue = recaptcha.current.getValue()

        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!')
            setLoader(false)
          } else {

            // make form submission
            // alert('Form submission successful!')
            setLoader(true)
        if(name !== "" && email !== "" && message !== ""){
            try {
                
                var templateParams = {
                    name: name,
                    email: email,
                    phonenumber: phone,
                    messageBody: message
                    
                };

                emailjs.send(process.env.REACT_APP_SERVICEID, process.env.REACT_APP_TEMPLATEID, templateParams);
                clearFields()
                setLoader(false)
                // console.log(resp.data.message)
                
                setSuccess(true)
                recaptcha.current.reset();
            
            } catch (error) {
                setLoader(false)
                recaptcha.current.reset();
                // console.log(error);

                let [response] = error.response.data.errors

                // console.log(response)
                if(response.field === "fullName"){
                    setnameError(response)
                }if(response.field === "email"){
                    setemailError(response)
                }if(response.field === "phoneNumber"){
                    setphoneError(response)
                }if(response.field === "messageBody"){
                    setmessageError(response)
                }

                // if(error.response.data.statusCode === 500){
                //     setmainError(true)
                //     setTimeout(()=>{
                //         setmainError(false)
                //     }, 5000)
                // }
                clearFields()
            }
        }else{
            alert('form fields cannot be empty')
            recaptcha.current.reset();
            setLoader(false)
        }
    }

    }
    
  return (
    <>
        {/* Set Page title */}
        <PageTitle title="Get in Touch | SeamHealth Website " /> 
        <div id='address-section' className="contact-section pt-28 md:pt-52 p-4 md:p-8 flex justify-between flex-col gap-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div data-aos="fade-up" className='text-white '>
                <Tag title={"Contact us"} alt={true} />
                <h4 data-aos="fade-up" className="heading-four">We'd love to hear from you</h4>
              </div>
              <p data-aos="fade-up" className='text-white'>
                Thank you for visiting our website. If you have any questions or comments about our services, feel free to reach out. We aim to respond to all inquiries within 24 hours and look forward to working with you.
              </p>
          </div>

          <div className="grid md:grid-cols-2 gap-12 md:gap-8 items-end">
              <div data-aos="fade-up" className='text-white grid grid-cols-1 gap-6'>
                <div>
                    <p className='text-primary50'>Email</p>
                    <p>info@seamhealthgroup.com</p>
                </div>

                <div>
                    <p className='text-primary50'>Phone</p>
                    <p >+2348030726143</p>
                </div>

                <div>
                    <p className='text-primary50'>Office address</p>
                    <p>International Research Center of Excellence Towers. 6, Emeritus Umar Shehu Ave. (5th Floor, Tower A), Institutions and Research District, Abuja, Nigeria.</p>
                </div>
              </div>
              
              <div className='map-container'>
                    <div><img className='map' draggable={false} src={MapBG} alt="" /></div>
                  <a href="#message-section" className='py-4 md:py-0'>
                    <div className='message-cta flex gap-3 items-center justify-center md:justify-end'>
                        <img src={CircleIcon} alt="" />
                        <p className='text'>Write us a message</p>
                    </div>
                              </a>
              </div>
          </div>
        </div>
            
        <div id='message-section' className="message-section pt-28 md:pt-52 p-4 md:p-8 grid grid-cols-1 md:grid-cols-2">
            <div className="flex flex-col justify-between items-start">
                <div data-aos="fade-up" className=''>
                    <h4 className="heading-four mb-4">Write us a message</h4>
                    <p className='text-gray'>Another option is to contact us through the provided email address and phone number above</p>
                </div>

                <a href="#address-section" className=''>
                <div className='address-cta md:flex gap-3 items-center justify-end hidden '>
                    <img src={CircleIconAlt} alt="" />
                    <p className='text'>Our Address</p>
                </div>
            </a>
            </div>

            <div data-aos="fade-up" className='form w-max mt-8 md:mt-0' >
                {success ? <div className="success-message p-4 rounded-lg w-full text-center text-green-700 bg-green-200">
                    Your message has been sent, our team will reach out to you shortly
                </div> : <></>}
                <form method='POST' onSubmit={submitForm}>
                <div className="f-inner">
                    <div className="form-group w-full">
                        Name
                        <input value={name} type="text" onChange={(e) => setName(e.target.value)} className="form-control w-full" placeholder='Name' required />
                        <img src={UserIcon} alt="" />
                        {nameError != null ? <p className='text-red small-text'>{nameError.message}</p>: <></>}
                    </div>
                    

                    <div className="form-group w-full">
                        Email
                        <input value={email} type="email" onChange={(e) => setEmail(e.target.value)} className="form-control w-full" placeholder='Email address' required />
                        <img src={EmailIcon} alt="" />
                        {emailError != null ? <p className='text-red small-text'>{emailError.message}</p>: <></>}
                    </div>
                    

                    <div className="form-group w-full">
                        Phone number
                        <input value={phone} type="tel" onChange={(e) => setPhone(e.target.value)} className="form-control w-full" placeholder='Phone number' />
                        <img src={PhoneIcon} alt="" />
                        {phoneError != null ? <p className='text-red small-text'>{phoneError.message}</p>: <></>}
                    </div>
                    

                    <div className=" w-full">
                        Message
                        <textarea value={message} onChange={(e) => setMessage(e.target.value)} name="" id="" cols="4" rows={10} className='form-control w-full' placeholder='Write your message' required></textarea>
                        {messageError != null ? <p className='text-red small-text'>{messageError.message}</p>: <></>}
                    </div>
                    <ReCAPTCHA 
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        ref={recaptcha}
                    />
                        
                    <div className='w-full'>
                        <button type='submit'  className={!submitBtn ? 'btn btn-primary w-full md:w-max disabled' : 'btn btn-primary w-full md:w-max'}>{loader ? 'Sending...' : 'Send message'}</button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </>
  )
}

export default Getintouch