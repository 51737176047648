import React from 'react'
import Navigation from '../components/Navigation'

const Header = () => {
  return (
    <>
        <Navigation />
    </>
  )
}

export default Header