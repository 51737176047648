import React from 'react'

const FeaturesCard = ({ title, desc, image }) => {
  return (
    <>
        <div className="features-card">
                <div className="image mb-6">
                    <img src={image} alt="" draggable="false" />
                </div>

                <div className="details flex flex-col gap-4">
                    <h6 className="heading-six text-gray500 font-semibold">{title}</h6>
                    <p className="base-text text-gray100">{desc}</p>
                </div>
        </div>
    </>
  )
}

export default FeaturesCard