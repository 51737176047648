import React from 'react'

const Tag = ({ title, alt }) => {
  return (
    <>
        <div className={alt ? 'tag alt' : 'tag'}>
            {title}
        </div>
    </>
  )
}

export default Tag