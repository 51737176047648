import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../assets/svgs/logo/seamhealth+logo.svg'
import LogoAlt from '../assets/svgs/logo/seamhealth+logo+alt.svg'
import ArrowUpRight from '../assets/svgs/icons/arrow-up-right.svg'
import PageTitle from './PageTitle'
import MenuIcon from '../assets/svgs/icons/menu.svg'
import CloseIcon from '../assets/svgs/icons/close+icon.svg'

const Navigation = () => {

   const [scrollYPosition, setScrollYPosition] = React.useState(0);
   const location = useLocation();
   const [mobileNav, setMobileNav] = React.useState(false)


const handleScroll = () => {
    const newScrollYPosition = window.pageYOffset;
    setScrollYPosition(newScrollYPosition);
};

React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);


// console.log(scrollYPosition)
// console.log(location)

  return (

    <>
        <div className={
            location.pathname == '/' && scrollYPosition > 653 ? 'navbar flex items-center justify-between p-4 md:p-8 py-4 md:py-6 bg-white text-gray shadow-md' 
            : location.pathname == '/get-in-touch' && scrollYPosition > 653 ? 'navbar flex items-center justify-between p-4 md:p-8 py-4 md:py-6 bg-white text-gray shadow-md' 
            // : location.pathname != '/get-in-touch' && location.pathname != "" ? 'navbar flex items-center justify-between p-4 md:p-8 py-4 md:py-6 text-gray' 
            : location.pathname != "/" && scrollYPosition > 100 ? 'navbar flex items-center justify-between p-4 md:p-8 py-4 md:py-6 bg-white text-gray shadow-md'
            // : scrollYPosition > 100  && location.pathname !="/" ?  'navbar flex items-center justify-between p-4 md:p-8 py-4 md:py-6 bg-white text-gray shadow-md'
            : 'navbar flex items-center justify-between p-4 md:p-8'}>
            <div>
                {/* Logo */}
                
                <a href="/"><img className='h-[22px] md:h-[27px]' src={
                    location.pathname == '/' && scrollYPosition > 653 ? LogoAlt 
                    : location.pathname == '/get-in-touch' && scrollYPosition > 653 ? LogoAlt 
                    : location.pathname != '/' && location.pathname != '/get-in-touch' ? LogoAlt
                    : Logo} alt="" /></a>
            </div>
            <div>
                {/* link */}
                <ul className='hidden md:flex header-links'>
                    <Link to="/" className={
                        location.pathname != '/' ? ''
                        : location.pathname == '/' && scrollYPosition > 653 ? 'active' 
                        : location.pathname == '/get-in-touch' && scrollYPosition > 653 ? 'active ' 
                        : location.pathname != '/' && location.pathname != '/get-in-touch' ? 'active text-primary500'
                        : 'active '}><li>Home</li></Link>
                    
                    <Link to="/about" className={location.pathname == '/about' && 'active text-primary500' }><li>About</li></Link>
                    <Link to="/services"><li>Services</li></Link>
                    <Link to="/projects"><li>Projects</li></Link>
                </ul>
            </div>
            <div className='hidden md:block'>
                <Link to={'/get-in-touch'} className={
                    location.pathname == '/get-in-touch' && scrollYPosition > 653 ? 'btn btn-primary text-white' 
                    : location.pathname == '/' && scrollYPosition > 653 ? 'btn btn-primary text-white' 
                    : location.pathname != '/' && location.pathname != '/get-in-touch' ? 'btn btn-primary text-white' 
                    
                    : 'btn btn-secondary text-white'}>Get in touch <img src={ArrowUpRight} alt="" /> </Link>
            </div>
            <div className='md:hidden'>
            <Link to={'/get-in-touch'} 
            onClick={() => {setMobileNav(!mobileNav);}}
            className={
                    location.pathname == '/get-in-touch' && scrollYPosition > 653 ? 'btn btn-primary text-white' 
                    : location.pathname == '/' && scrollYPosition > 653 ? 'btn btn-primary text-white' 
                    : location.pathname != '/' && location.pathname != '/get-in-touch' ? 'btn btn-primary text-white' 
                    
                    : 'btn btn-secondary text-white'}>{mobileNav ? <img src={CloseIcon} alt="" /> : <img src={MenuIcon} alt="" /> }</Link>
            </div>
        </div>

        {mobileNav ? <div className='mobile-nav'>
            
        <ul className='flex flex-col md:hidden header-links h-full justify-center items-center' style={{gap : 48}}>
                    <Link to="/" onClick={() => {setMobileNav(!mobileNav);}} className={
                        location.pathname != '/' ? ''
                        : location.pathname == '/' && scrollYPosition > 653 ? 'active' 
                        : location.pathname == '/get-in-touch' && scrollYPosition > 653 ? 'active ' 
                        : location.pathname != '/' && location.pathname != '/get-in-touch' ? 'active text-primary500'
                        : 'active '}><li>Home</li></Link>
                    
                    <Link to="/about" onClick={() => {setMobileNav(!mobileNav);}} className={location.pathname == '/about' && 'active text-primary500' }><li>About</li></Link>
                    <Link to="/services" onClick={() => {setMobileNav(!mobileNav);}}><li>Services</li></Link>
                    <Link to="/projects" onClick={() => {setMobileNav(!mobileNav);}}><li>Projects</li></Link>
                    <Link to={'/get-in-touch'} onClick={() => {setMobileNav(!mobileNav);}} className={'btn btn-secondary text-white'}>Get in touch <img src={ArrowUpRight} alt="" /> </Link>
                </ul>
        </div> : <></>}
        {mobileNav ? <div className="mobile-overlay"></div> : <></>}
    </>
  )
}

export default Navigation