import React from 'react'

const Chip = ({ title, icon, persist = false }) => {
  return (
    <>
        <div className={persist ? 'chip-fixed p-3 flex persist items-center gap-x-3 bg-white rounded-2xl' : 'chip p-3 flex persist items-center gap-x-3 bg-white rounded-2xl'} >
            <img src={icon} alt="" />
            <p className="text-gray">{title}</p>
        </div>
    </>
  )
}

export default Chip