import React from 'react';
import './assets/css/style.css'
import ReactDOM from 'react-dom/client';
import App from './App';
import Layout from './layouts/Layout'
import Home from './pages/Home';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Getintouch from './pages/Getintouch';
import About from './pages/About';
import 'swiper/css/bundle';
import Services from './pages/Services';
import Servicedetail from './pages/Servicedetail';
import Project from './pages/Project';
import Projectdetail from './pages/Projectdetail';
import Projectdetail2 from './pages/Projectdetail2';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    element: <Layout />,
    // errorElement: <Error />,

    children : [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/get-in-touch",
        element: <Getintouch />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/services",
        element: <Services />
      },
      {
        path: "/services/:service_id",
        element: <Servicedetail />
      },
      {
        path: "/projects",
        element: <Project />
      },
      {
        path: "/projects/01/:project_id",
        element: <Projectdetail />
      },
      {
        path: '/projects/02/:project_id',
        element: <Projectdetail2 />
      },
      
    ],
    // errorElement: <Error />
  }
]);

root.render(<RouterProvider router={router} />);

reportWebVitals();
