import React from 'react'

const ValuesCard = ({ title, desc, icon, position }) => {
  return (
    <>
        <div data-aos="fade-up" className='blue-alt-card'>
                <div className=" flex justify-between items-center mb-3">
                    <img src={icon} alt="" />
                    <div className='text-gray50'>{position}</div>
                </div>
            <div className='texts text-white gap-3 flex flex-col'>
                <div className="title font-semibold">{title}</div>
                <div className='desc text-primary25 font-normal'>{desc}</div>
            </div>
        </div>
    </>
  )
}

export default ValuesCard