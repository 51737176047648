import React from 'react'
import { Link } from 'react-router-dom'
import ArrowRight from '../assets/svgs/icons/arrow-right.svg'

const ServiceCard = ({ img, title, desc, link }) => {
    
  return (
    <>
        <Link to={link}> 
            <div data-aos="fade-up" className='service-card flex flex-col justify-end items-center'
            style={{
                backgroundImage : 'url('+img+')',
                }}>
                {/* <img src={img} alt="" /> */}
                <div className="details flex flex-col items-start gap-7">
                    <div className="flex flex-col gap-4">
                        <h6 className="heading-six font-semibold">{title}</h6>
                        <p className="desc text-gray">{desc}</p>
                    </div>
                    <Link to={link} className='btn text-primary500' style={{padding : 0}}>Read more <img src={ArrowRight} alt="" /> </Link>
                </div>
            </div>
        </Link>
    </>
  )
}

export default ServiceCard