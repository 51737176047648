import React from 'react'

const BenefitCard = ({ position, title, desc }) => {
  return (
    <>
        <div className='benefit-card'>
            <h2 className="position mb-6">{position}</h2>
            <h6 className="heading-six mb-4">{title}</h6>
            <p className="text-gray100">{desc}</p>
        </div>
    </>
  )
}

export default BenefitCard