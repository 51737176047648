import React from 'react'
import { Link } from 'react-router-dom'
import PageTitle from '../components/PageTitle'
import ServiceCard from '../components/ServiceCard'
import ConsultationImg from '../assets/images/Consultation.webp'
import WebDevImg from '../assets/images/development.webp'
import AppDevImg from '../assets/images/app+development.webp'
import ProjectCard from '../components/ProjectCard'
import ElsrtMockup from '../assets/images/elsrt+mockup.png'
import ClientshotMockup from '../assets/images/clientshot+mockup.png'
import ArrowUpRight from '../assets/svgs/icons/arrow-up-right.svg'
import ElsrtLogo from '../assets/svgs/logo/elsrt+logo.svg'
import ClientshotLogo from '../assets/svgs/logo/Clientshot_logo_white.svg'
import SystemIntImg from '../assets/images/system+integration.webp'
import LegacySysImg from '../assets/images/legacy+system.webp'
import SupportImg from '../assets/images/support.webp'




const Services = () => {
  React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page
  return (
    <>
        {/* Set Page title */}
      <PageTitle title="Services | SeamHealth Website" /> 
      <div className="services-top-section h-full flex flex-col items-center justify-center">
            <div className='hero-texts flex flex-col gap-6 items-center'>
                <h3 data-aos="fade-up" className='heading-three'>A comprehensive suite of services designed for healthcare organizations.</h3>
                <p data-aos="fade-up" className='body'>We prioritize our clients by attentively listening to their needs, collaborating closely with their teams, and tailoring solutions specifically for them.</p>
            </div>
        </div>

        {/* Services list */}
        <div className=''>
            <h4 className="heading-four py-24 pb-8 md:pb-8 text-center">Our Services</h4>
          </div>
        <section className='py-24 pt-0 md:pt-0 md:py-52 service-list grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-24 md:gap-y-52 p-4 md:p-8'>
          
            <ServiceCard img={ConsultationImg} 
            title={"Consultation"} 
            link={"/services/1"}
            desc="Offering expert advice and strategies to optimize your healthcare operations and technology implementations." />

<ServiceCard img={WebDevImg} 
            title={"Custom Software Development"} 
            link={"/services/2"}
            desc="Creating tailored healthcare solutions from scratch, designed to meet the unique needs of your institution or practice." />

<ServiceCard img={AppDevImg} 
            title={"Mobile App Development"} 
            link={"/services/3"}
            desc="Building user-friendly mobile applications for both patients and healthcare providers, enabling access to health services anytime, anywhere." />

<ServiceCard img={SystemIntImg} 
            title={"System Integration"} 
            link={"/services/4"}
            desc="Bridging gaps between different healthcare systems to ensure seamless data transfer and improved interoperability." />

<ServiceCard img={LegacySysImg} 
            title={"Legacy System Modernization"} 
            link={"/services/5"}
            desc="Upgrading outdated software systems to improve functionality, security, and user experience." />

<ServiceCard img={SupportImg} 
            title={"Support and Maintenance"} 
            link={"/services/6"}
            desc="Providing ongoing maintenance and technical support to ensure your healthcare software runs smoothly and remains up-to-date." />
        </section>


        {/* recent project */}

        <section className="recent-projects p-4 md:p-8 py-52">
          <div className='flex flex-col md:flex-row justify-between gap-4 md:items-center'>
          
              <div className="flex gap-y-2 flex-col">
                <h4 data-aos="fade-up" className="heading-four">Previous Achievements with Leading Organizations</h4>
                <p data-aos="fade-up" className='text-gray'>
                Explore our portfolio of successful projects completed for reputable organizations.
                </p>
              </div>
              <div data-aos="fade"><button className='btn btn-primary w-max'>See all projects <img src={ArrowUpRight} alt="" /></button></div>
          </div>

          <div className='grid md:grid-cols-2 my-24 gap-8'>
            <ProjectCard 
              image={ClientshotMockup} 
              logo={ClientshotLogo} 
              desc={"Clientshot is a customer satisfaction tool that helps businesses collect and analyze feedback from their customers. "}
              link={'/'}
              />

              <ProjectCard 
              image={ElsrtMockup} 
              logo={ElsrtLogo} 
              desc={"Electronic laboratory sample referral & tracking system (ELSRT) is a software-based solution with features that support a modern laboratory’s operations."}
              link={'/'}
              />

          </div>
        </section>
    </>
  )
}

export default Services