import {React, useEffect} from 'react'

const ApproachCard = ({ title, desc, icon, position }) => {
  
  return (
    <>
        <div className='blue-card'>
                <div className=" flex justify-between items-center mb-6">
                    <img src={icon} alt="" />
                    <div className='text-gray50'>{position}</div>
                </div>
            <div className='texts text-white gap-4 flex flex-col'>
                <div className="title">{title}</div>
                <p className='desc font-normal'>{desc}</p>
            </div>
        </div>
    </>
  )
}

export default ApproachCard