import {React} from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import { useEffect } from 'react';

const Layout = () => {

  return (
    <>
        <Header />
            <Outlet />
        <Footer />
    </>
  )
}

export default Layout